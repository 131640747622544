import { request } from '@/util/request';
import { message } from 'ant-design-vue';
export async function getNewbieprivRule() {
  const url = `/boss/operation/newbiepriv/rule`;
  const res = await request.get(url);
  if (res.status == 200 && res.data) {
    return res.data;
  }
  message.error('获取规则失败，请刷新重试');
  return '';
}
export async function editNewbieprivRule(rule) {
  const url = `/boss/operation/newbiepriv/rule/0`;
  const res = await request.put(url, { rule });
  if (res.status == 200) {
    message.success('编辑成功');
    return true;
  }
  message.error('编辑规则失败');
  return false;
}
export async function getNewbieprivList(params) {
  const url = `/boss/operation/newbiepriv/product`;
  const res = await request.get(url, { params });
  if (res.status == 200 && res.data) {
    return res.data;
  }
  message.error('获取列表失败');
  return {
    current: 1,
    detail: [],
    last: 0,
    limit: 1,
    total: 1,
  };
}

export async function putNewbiepriv(params: {
  add?: {
    price?: number;
    product_id?: number;
    sort?: number;
  }[];
  del?: number[];
  edit?: {
    id?: number;
    price?: number;
    sort?: number;
  }[];
}) {
  const url = `/boss/operation/newbiepriv/product/0`;
  const res = await request.put(url, params );
  if (res.status == 200 && res.data) {
    message.success('操作成功')
    return true;
  }
  message.error('操作失败');
  return false;
}
